<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-md-6">
        <h2 class="text-dark d-inline-block m-0">All Release Updates</h2>
      </div>
      <div class="col-md-6">
        <div class="float-right"></div>
      </div>
    </div>

    <div>
      <SectionLoading v-if="loading" text="All Release Updates loading..." />
      <div v-else>
        <div>
          <div class="card siteCard">
            <div class="table-responsive py-2">
              <vs-table
                id="div-with-loading"
                max-items="10"
                stripe
                :data="contents.data"
              >
                <template slot="thead">
                  <vs-th class="text-dark" scope="col">S/N</vs-th>
                  <vs-th class="text-dark" scope="col">Date</vs-th>
                  <vs-th class="text-dark" scope="col">Update</vs-th>
                  <vs-th class="text-dark" scope="col">Action</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(update, indextr) in data">
                    <td>
                      <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                    </td>

                    <td>
                      <div class="font-weight-bold">
                        <p class="text-dark mb-0 title">
                          {{ moment(new Date(update.created_at)).fromNow() }}
                        </p>
                      </div>
                    </td>

                    <td>
                      <div v-if="update.user">
                        <router-link
                          class="font-weight-bold"
                          :to="`/artists/${update.user.uuid}`"
                        >
                          {{ update.user.name }}
                        </router-link>
                        updated release -
                        <router-link
                          class="font-weight-bold text-capitalize"
                          :to="`/release/${update.id}`"
                        >
                          {{ update.title }}
                        </router-link>
                      </div>
                    </td>

                    <td>
                      <router-link
                        :to="`/release/${update.id}`"
                        class="btn btn-primary btn-site px-5"
                      >
                        View release
                      </router-link>
                    </td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SectionLoading from "../../../components/SectionLoading";

export default {
  data() {
    return {
      loading: false,
      contents: {
        data: [],
      },
      contentsRequests: {
        data: [],
      },
    };
  },
  mounted() {
    this.getReleaseUpdates();
  },
  components: {
    SectionLoading,
  },
  methods: {
    getReleaseUpdates() {
      this.loading = true;

      let fetch = {
        path: `admin/releases/update/logs`,
        data: {},
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.contents = resp.data;

          this.$vs.loading.close();
          this.loading = false;
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.loading = false;

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to get Release updates",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Unable to get Release updates",
              this.$toastPosition
            );
          }
        });
    },
  },
};
</script>
